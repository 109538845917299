//import Validate from "@/plugins/Validation/Branch/branch"
export default {
    data() {
        return {
            loading: false,
            TableLoading: false,
            factoryID: '',
            server_errors: {
                truck_id: '', phone: ''
            },
            listFactories: [],
            factorySelected: "",
            filterFactory: "",

            factory: {},
            sorting_center_edit: {},
            factories: [],
            sorting_center: {},
            selectedDistrict: "",
            selectedVillage: "",
            listDistricts: [],
            listVillages: [],
            imageFile: "",
            imageUrl: "", //Location
            center: {
                lat: 18.1189434, lng: 102.290218,
            },
            markers: [],
            currentPlace: null,
            markerOptions: {
                // eslint-disable-next-line global-require
                url: require('@coms/../../src/assets/vari_pin.png'), size: {
                    width: 35, height: 55, f: 'px', b: 'px',
                }, scaledSize: {
                    width: 35, height: 55, f: 'px', b: 'px',
                },
            },


            headers: [
                {
                    text: "ລະຫັດ", align: "start", sortable: false, value: "id",
                },
                {
                    text: "ຊື່", align: "start", sortable: false, value: "name",
                },
                {text: "ເບີໂທ", value: "phone"}, {text: "Email", value: "email"},
                {
                    text: "ທີ່ຕັ້ງ",
                    value: "village"
                },
                {text: "ຈຸດສັງເກດ", value: "address"},
                // {text: "", value: "image",width: "120"},
                {text: "Created", value: "created_at"}, {
                    text: "Actions",
                    value: "actions",
                    sortable: false
                },],
            idRules: [v => !!v || 'Factory ID is required', v => (v && v.length >= 2) || 'Truck ID must be more than 5 characters',],
            phoneRules: [(v) => !!v || "Phone is required", v => (v && v.length >= 4 && v.length <= 11) || 'Phone number must be  4 - 11 numbers',],
            nameRules: [v => !!v || 'Name is required', v => (v && v.length >= 2) || 'Name must be less than 2 characters',],
            passwordRules: [v => !!v || 'Password is required', v => (v && v.length >= 6) || 'Password must be less than 6 characters',],
            emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",],
            ruleDistrict: [(v) => !!v || "District is required"],
            ruleVillage: [(v) => !!v || "Village is required"],
            ruleAddress: [(v) => !!v || "Address is required"],

            toast: {
                value: true, color: 'success', msg: 'Success'
            },
            toast_error: {
                value: true, color: 'error', msg: 'Something when wrong!'
            }
        }
    }, methods: {
        onFileChange(e) {
            let input = e.target;
            let file = e.target.files[0];
            this.imageFile = input.files[0];
            this.imageUrl = URL.createObjectURL(file);
            this.server_errors.imageFile = '';
            let formData = new FormData();
            formData.append("imageFile", this.imageFile);
            this.$axios.post('/upload-image', formData)
                .then(res => {
                    if (res.status == 200) {
                        this.imageFile = res.data.fileName;
                    }
                }).catch(error => {
                this.imageFile = '';
                if (error.response.status == 422) {
                    const obj = error.response.data.errors;
                    for (let [key, user] of Object.entries(obj)) {
                        this.server_errors[key] = user[0];
                    }
                }
            });

        },

        OpenModalAdd() {
            this.$store.commit("modalAdd_State", true);
            this.getAddress();
        }, AddItem() {
            if (this.$refs.form.validate() == true) {
                this.Submit();
            }
        }, Submit() {
            const data = {
                factory_id: this.factorySelected,
                name: this.sorting_center.name,
                phone: this.sorting_center.phone,
                email: this.sorting_center.email,
                email_contact: this.sorting_center.email_contact,
                address: this.sorting_center.address,
                village_id: this.selectedVillage.id,
                image: this.imageFile,
                lat: this.center.lat,
                lng: this.center.lng,
                password: this.sorting_center.password,
            }
            // console.log(this.imageFile);
            // console.log(data);

            this.loading = true;
            this.$axios.post('factory/add-warehouse', data)
                .then(res => {
                    if (res.data.success == true) {
                        setTimeout(() => {
                            this.loading = false;
                            this.CloseModalAdd();
                            this.factory = {}, this.getData();
                            this.reset();
                            this.$store.commit("Toast_State", this.toast);
                        }, 1000);
                    }
                }).catch(error => {
                this.loading = false;
                this.$store.commit("Toast_State", this.toast_error);
                this.getData();
                if (error.response.status == 422) {
                    let obj = error.response.data.errors;
                    for (let [key, truck] of Object.entries(obj)) {
                        this.server_errors[key] = truck[0];
                    }
                }
            });
        }, CloseModalAdd() {
            this.sorting_center = {}, this.reset();
            this.$store.commit("modalAdd_State", false);
        }, OpenModalEdit(item) {
            this.sorting_center_edit = item;
            this.getAddress();
            this.$store.commit("modalEdit_State", true);
        },

        UpdateItem() {
            if (this.$refs.form.validate() == true) {
                this.loading = true;
                //this.truck_edit.driverId = this.factory_edit.driverUser;

                this.$axios.put('factory/edit-warehouse/' + this.sorting_center_edit.id, this.sorting_center_edit)
                    .then(res => {
                        if (res.data.success == true) {
                            setTimeout(() => {
                                this.loading = false;
                                this.CloseModalEdit();
                                this.reset();
                                this.getData();
                                this.$store.commit("Toast_State", this.toast);
                            }, 300);
                        }
                    }).catch(error => {
                    this.loading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                    this.getData();
                    if (error.response.status == 422) {
                        let obj = error.response.data.errors;
                        for (let [key, truck] of Object.entries(obj)) {
                            this.server_errors[key] = truck[0];
                        }
                    }
                });
            }
        },

        CloseModalEdit() {
            this.sorting_center_edit = {};
            this.$store.commit("modalEdit_State", false);
        },

        getData() {
            if (this.filterFactory) {
                this.TableLoading = true;
                this.$axios.get('factory/list-warehouses/' + this.filterFactory).then(res => {
                    this.factories = res.data.data;
                    this.TableLoading = false;
                }).catch(() => {
                    this.TableLoading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                });
            }
        },
        getListFactory() {
            this.$admin.get('list-factories').then(res => {
                this.listFactories = res.data.data.data;
                this.factorySelected = res.data.data.data[0].id;
                this.filterFactory = res.data.data.data[0].id;
            }).catch(() => {
                this.$store.commit("Toast_State", this.toast_error);
            });
        },

        getAddress() {
            this.$admin
                .get("list-address")
                .then((res) => {
                    this.listVillages = res.data.listVillages;
                    this.listDistricts = res.data.districts;
                    this.filterVillages = res.data.listVillages;
                })
                .catch(() => {
                    this.$store.commit("Toast_State", this.toast_error);
                });
        }, FilterVillages(districtId) {
            const result_checking = this.filterVillages.filter((item) => {
                return item.district_id == districtId;
            });
            this.listVillages = result_checking;
            this.selectedVillage = {...this.listVillages[0]};
        },

        closeDelete() {
            this.$store.commit("modalDelete_State", false);
        }, deleteItem(id) {
            this.factoryID = id;
            this.$store.commit("modalDelete_State", true);
        }, deleteItemConfirm() {
            this.loading = true;
            this.$axios.delete('factory/delete-warehouse/' + this.factoryID).then(res => {
                if (res.data.success == true) {
                    setTimeout(() => {
                        this.getData();
                        this.$store.commit("modalDelete_State", false);
                        this.loading = false;
                        this.$store.commit("Toast_State", this.toast);
                    }, 1000);
                }
            }).catch(() => {
                this.getData();
                this.$store.commit("modalDelete_State", false);
                this.loading = true;
                this.$store.commit("Toast_State", this.toast_error);
            })
        }, reset() {
            this.$refs.form.reset();
        },

        //Set Googlemap Api
        createNewAddressName() {
            const CUSTOMIZE = '#CUSTOM ADDRESS:';
            return this.isCreate ? this.currentAddress : `${CUSTOMIZE} ${this.center.lat}, ${this.center.lng}`;
        }, onLocation(evt) {
            this.center.lat = evt.latLng.lat();
            this.center.lng = evt.latLng.lng();
            this.address = this.createNewAddressName();
            this.branch_edit.lat = this.center.lat;
            this.branch_edit.lng = this.center.lng;
        }, setPlace(place) {
            this.currentPlace = place;
            this.placeMarker();
        }, placeMarker() {
            this.markers = [];
            this.places = [];
            if (this.currentPlace) {
                const marker = {
                    lat: this.currentPlace.geometry.location.lat(), lng: this.currentPlace.geometry.location.lng(),
                };
                this.markers.push({position: marker});
                this.center = marker;
                this.animateMarker();
            } else {
                const marker = {
                    lat: this.center.lat, lng: this.center.lng,
                };
                this.markers.push({position: marker});
                this.animateMarker();
            }
            // set address
            if (this.$refs.searchInput) {
                this.address = this.$refs.searchInput.$el.value;
            } else {
                // this.address = this.currentPlace.formatted_address;
            }
            this.onDataChange();
        }, animateMarker() {
            this.$nextTick(() => {
                // const obj = this.$refs.markers[0].$markerObject;
                // if (obj) {
                //     obj.setAnimation(1);
                //     clearTimeout(this.timer);
                //     this.timer = setTimeout(() => {
                //         obj.setAnimation(null);
                //     }, 800);
                // }
            });
        }, geolocate() {
            navigator.geolocation.getCurrentPosition((position) => {
                this.center = {
                    lat: position.coords.latitude, lng: position.coords.longitude,
                };
                this.placeMarker();
            });

        }, onDataChange() {
            this.$emit('onDataChange', {
                address: this.address, position: this.center,
            });
        }, onSave() {
            this.$emit('onSave', {
                address: (this.address || this.currentAddress || 'Unnamed Location'),
                position: this.center,
                isCreate: this.isCreate,
            });
        },
    }, watch: {
        selectedDistrict: function (dist_id) {
            this.FilterVillages(dist_id);
        },
        filterFactory: function () {
            this.getData();
        },
        'sorting_center.email': function () {
            this.server_errors.email = '';
        }, 'sorting_center.name': function () {
            this.server_errors.name = '';
        }, 'sorting_center.phone': function () {
            this.server_errors.phone = '';
        },

        'sorting_center_edit.email': function () {
            this.server_errors.email = '';
        }, 'sorting_center_edit.name': function () {
            this.server_errors.name = '';
        }, 'sorting_center_edit.phone': function () {
            this.server_errors.phone = '';
        },
    }, created() {
        this.getListFactory();
        this.getData();
    }, mounted() {
        this.geolocate();
    }
}
