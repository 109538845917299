<template>
  <div>
    <v-row justify="end">
      <div class="my-2">
        <v-btn class="btn-primary" fab small dark @click="OpenModalAdd()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </v-row>
    <v-row>
      <v-col cols>
        <v-select
            required
            outlined
            dense
            v-model="filterFactory"
            :items="listFactories"
            item-text="name"
            item-value="id"
        >
        </v-select>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="factories"
      :items-per-page="15"
      class="elevation-1"
      :loading="TableLoading"
      :disabled="TableLoading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item.village="{ item }">
        <div>
          {{ item.village.name }},  {{ item.village.district.name }}
        </div>
      </template>
<!--      <template v-slot:item.image="{ item }">-->
<!--        <v-avatar>-->
<!--          <img :src="`${baseUrl}`+item.image">-->
<!--        </v-avatar>-->
<!--      </template>-->
      <template v-slot:item.created_at="{ item }">
        <div>
          {{ item.created_at | formatDate }}
        </div>
      </template>
      <!--Action -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="OpenModalEdit(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item.id)"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <!--Delete Modal-->
    <ModalAdd>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span>ເພີ່ມສາງໂຮງງານ</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col>
                    <div class="field">
                      <div class="file is-large is-boxed">
                        <label class="file-label">
                          <input
                              @change="onFileChange"
                              class="file-input input-file-image"
                              type="file"
                              name="image"
                              accept="image/*"
                              ref="image"
                              hidden
                          />
                          <span class="file-cta">
                            <span class="file-icon">
                              <v-icon
                                  style="
                                  font-size: 60px !important;
                                  color: #719aff;
                                  cursor: pointer;
                                "
                                  class="fas fa-cloud-upload"
                              >mdi-cloud-upload</v-icon
                              >
                            </span>
                            <span
                                class="file-label"
                                style="
                                margin-top: 10px;
                                text-transform: uppercase;
                                padding-top: 20px;
                              "
                            >
                              Choose Profile
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row v-if="server_errors.image">
                  <v-col>
                    <p class="errors">
                      {{ server_errors.image }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="mt-5" v-if="imageFile">
                    <v-avatar class="avatar rounded" size="94px">
                      <img :src="imageUrl" alt=""/>
                    </v-avatar>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols>
                    <v-select
                        required
                        outlined
                        dense
                        v-model="factorySelected"
                        :items="listFactories"
                        item-text="name"
                        item-value="id"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="ຊື່ສາງໂຮງງານ *"
                        required
                        v-model="sorting_center.name"
                        :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="Phone *"
                        required
                        v-model="sorting_center.phone"
                        :rules="phoneRules"
                        type="number"
                        class="input-number"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.phone }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="Email Contact *"
                        required
                        v-model="sorting_center.email_contact"
                        :rules="emailRules"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.email_contact }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="Email *"
                        required
                        v-model="sorting_center.email"
                        :rules="emailRules"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.email }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete
                        dense
                        required
                        v-model="selectedDistrict"
                        :items="listDistricts"
                        item-text="name"
                        item-value="id"
                        label="ເລືອກເມືອງ *"
                        :rules="ruleDistrict"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                        dense
                        required
                        v-model="selectedVillage.id"
                        :items="listVillages"
                        item-text="name"
                        item-value="id"
                        label="ບ້ານ *"
                        :rules="ruleVillage"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="ຈຸດສັງເກດ *"
                        type="text"
                        v-model="sorting_center.address"
                        :rules="ruleAddress"
                        required
                        @keyup.enter="AddItem()"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.address }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Password*"
                        type="password"
                        v-model="sorting_center.password"
                        :rules="passwordRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <v-text-field
                        label="Latitude"
                        v-model="center.lat"
                        type="number"
                        class="input-number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="Longitude"
                        v-model="center.lng"
                        type="number"
                        class="input-number"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="mb-4">
                    <GmapMap
                        :center="center"
                        :zoom="16"
                        style="width: 100%; height: 450px"
                        :disableDefaultUI="true"
                    >
                      <GmapMarker
                          :key="index"
                          v-for="(m, index) in markers"
                          :position="m.position"
                          @click="center = m.position"
                          :draggable="true"
                          @dragend="onLocation"
                          :icon="markerOptions"
                          :animation="2"
                          ref="markers"
                      />
                    </GmapMap>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="CloseModalAdd()">
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="AddItem()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalAdd>

    <!--Edit Modal-->
    <ModalEdit>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span class="headline">ແກ້ໄຂສາງໂຮງງານ</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col>
                    <div class="field">
                      <div class="file is-large is-boxed">
                        <label class="file-label">
                          <input
                              @change="onFileChange"
                              class="file-input input-file-image"
                              type="file"
                              name="image"
                              accept="image/*"
                              ref="image"
                              hidden
                          />
                          <span class="file-cta">
                            <span class="file-icon">
                              <v-icon
                                  style="
                                  font-size: 60px !important;
                                  color: #719aff;
                                  cursor: pointer;
                                "
                                  class="fas fa-cloud-upload"
                              >mdi-cloud-upload</v-icon
                              >
                            </span>
                            <span
                                class="file-label"
                                style="
                                margin-top: 10px;
                                text-transform: uppercase;
                                padding-top: 20px;
                              "
                            >
                              Choose Profile
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row v-if="server_errors.imageFile">
                  <v-col>
                    <p class="errors">
                      {{ server_errors.imageFile }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="mt-5" v-if="imageFile">
                    <v-avatar class="avatar rounded" size="94px">
                      <img :src="imageUrl" alt=""/>
                    </v-avatar>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols>
                    <v-select
                        required
                        outlined
                        dense
                        v-model="sorting_center_edit.factory_id"
                        :items="listFactories"
                        item-text="name"
                        item-value="id"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="ຊື່ສາງໂຮງງານ *"
                        required
                        v-model="sorting_center_edit.name"
                        :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="Phone *"
                        required
                        v-model="sorting_center_edit.phone"
                        :rules="phoneRules"
                        type="number"
                        class="input-number"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.phone }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="Email ຕິດຕໍ່"
                        required
                        v-model="sorting_center_edit.email_contact"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.email_contact }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="Email *"
                        required
                        v-model="sorting_center_edit.email"
                        :rules="emailRules"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.email }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" v-if="sorting_center_edit.village">
                    <v-autocomplete
                        dense
                        required
                        v-model="sorting_center_edit.village.district.id"
                        :items="listDistricts"
                        item-text="name"
                        item-value="id"
                        label="ເລືອກເມືອງ *"
                        :rules="ruleDistrict"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                        dense
                        required
                        v-model="sorting_center_edit.village_id"
                        :items="listVillages"
                        item-text="name"
                        item-value="id"
                        label="ບ້ານ *"
                        :rules="ruleVillage"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Address"
                        type="text"
                        v-model="sorting_center_edit.address"
                        required
                        @keyup.enter="AddItem()"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <v-text-field
                        label="Latitude"
                        v-model="sorting_center_edit.lat"
                        type="number"
                        class="input-number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="Longitude"
                        v-model="sorting_center_edit.lng"
                        type="number"
                        class="input-number"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="mb-4">
                    <GmapMap
                        :center="center"
                        :zoom="16"
                        style="width: 100%; height: 450px"
                        :disableDefaultUI="true"
                    >
                      <GmapMarker
                          :key="index"
                          v-for="(m, index) in markers"
                          :position="m.position"
                          @click="center = m.position"
                          :draggable="true"
                          @dragend="onLocation"
                          :icon="markerOptions"
                          :animation="2"
                          ref="markers"
                      />
                    </GmapMap>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="CloseModalEdit()">
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="UpdateItem()"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ModalEdit>

    <!--Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete()"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            :loading="loading"
            :disabled="loading"
            @click="deleteItemConfirm()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>
  </div>
</template>

<script>
import manage from "../../mixins/sortingCenter/manage";
export default {
  mixins: [manage],
  metaInfo() {
    return {
      title: `ຂໍ້ມູນລົດຈັກສົ່ງ`,
      meta: [
        { name: 'description', content: 'ຂໍ້ມູນລົດຈັກສົ່ງ'},
      ]
    }
  },
};
</script>

<style>
</style>